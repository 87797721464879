.it-header-navbar-wrapper {

    background-color: $my-lightblue !important;
    //background-image: linear-gradient(to bottom, $my-lightblue, $my-blue)  !important;
    //border-bottom: 1px solid $my-blue !important;

    span {
            color: $my-blue !important;
        }

    .menu-wrapper {
        a:hover {
            text-decoration: underline !important;
            text-decoration-color: $my-blue !important;
            }
        
    }

    svg {
        //background-color: $my-lightblue !important;
        color: $my-blue !important;
        fill: $my-blue !important;
        //outline: none !important;
  
        &:hover {
          //background-color: red !important;
          filter: brightness(85%) !important;
        }
  
  
    }

    /*background-color: $my-lightblue !important;
    border-bottom: 1px solid $my-blue !important;

    span {
            color: $my-blue !important;
        }

    .menu-wrapper {
        a:hover {
            text-decoration: underline !important;
            text-decoration-color: $my-blue !important;
            }
        
    }*/
  
}


@media (min-width: 992px) {

    .it-header-navbar-wrapper {
        border-bottom: 1px solid $my-blue !important;

        //border-image: radial-gradient($my-blue, $my-lightblue) 30 !important;
        /*border-image: linear-gradient(to left, $my-blue, $my-lightblue) 30 !important;
        border-width: 1px !important;
        border-bottom-style: solid !important;*/

    }

}

@media (max-width: 991px) {

    .it-nav-wrapper {
        border-bottom: 1px solid $my-blue !important;

        /*border-image: linear-gradient(to left, $my-blue, $my-lightblue) 30 !important;
        border-width: 1px !important;
        border-bottom-style: solid !important;*/
    }

    /*.it-header-navbar-wrapper {

        .row {
            z-index: 2 !important;
        }

    }*/

}

/*.navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active {
    border-color: $my-blue !important;
  
    span {
      color: $my-blue !important;
    }
  }*/