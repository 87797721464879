.scroll-to-top {

    button {
        background-color: $my-yellow !important;
        border: 2px solid $my-darkblue !important;

        &:hover {
            filter: brightness(85%) !important;
        }

        .icon {
            color: $my-darkblue !important;
            fill: $my-darkblue !important;
        }
    }

}

.ArgomentoTitleWrapper {
    background: rgba(255, 255, 255, 0.9);

    //.listing-item {
        //box-shadow: none !important;
        //border: 1px solid $my-darkblue !important;
        //border: none !important;
    //}

    .icon-argument-container {
        background-color: $my-yellow !important;
        border: 1px solid $my-headerblue !important;

        .icon {
            color: $my-headerblue !important;
            fill: $my-headerblue !important;
        }

    }

}
