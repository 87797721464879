@import 'lignano/custom_variables';

@import 'addonsThemeCustomizationsVariables';
@import 'site-variables';
@import 'bootstrap-override/bootstrap-italia-site';
@import 'cms-ui';
@import 'mixins';
@import 'extras/blocks';
@import 'extras/sidebar';
@import 'extras/toolbar';
/* @import 'extras/chips';*/
@import 'extras/modals';
@import 'extras/search';
@import 'extras/checkbox';
@import 'extras/add_content_menu';
@import 'extras/forms';
@import 'extras/blocks-chooser';
@import 'extras/video';
@import 'extras/file-widget';
@import 'extras/tables';

/*** ItaliaTheme ***/
@import 'ItaliaTheme/css_variables';
@import 'ItaliaTheme/common';
@import 'ItaliaTheme/main';
@import 'ItaliaTheme/white-header';
@import 'ItaliaTheme/ar';
@import 'ItaliaTheme/home';
@import 'ItaliaTheme/sitemap';
@import 'ItaliaTheme/Addons/volto-gdpr-privacy';
@import 'ItaliaTheme/Components/unauthorized';
@import 'ItaliaTheme/Components/parentSiteMenu';
@import 'ItaliaTheme/Components/tertiaryMenu';
@import 'ItaliaTheme/Components/subsiteHeader';
@import 'ItaliaTheme/Components/subsiteFooter';
@import 'ItaliaTheme/Components/scrollToTop';
@import 'ItaliaTheme/Components/venue';
@import 'ItaliaTheme/Components/metadata';
@import 'ItaliaTheme/Components/galleryPreview';
@import 'ItaliaTheme/Components/locationsMap';
@import 'ItaliaTheme/Components/diffField';
@import 'ItaliaTheme/Components/cmp-timeline';
@import 'ItaliaTheme/Components/sideMenu';
@import 'ItaliaTheme/Components/loginAgid';
@import 'ItaliaTheme/Components/pager';
@import 'ItaliaTheme/Components/navigation';
@import 'ItaliaTheme/Blocks/subblocks-edit';
@import 'ItaliaTheme/Blocks/listing';
@import 'ItaliaTheme/Blocks/tableOfContents';
@import 'ItaliaTheme/Blocks/skeleton';
@import 'ItaliaTheme/Components/pageHeader';
@import 'ItaliaTheme/Blocks/common/searchBlockFilters';
@import 'ItaliaTheme/Blocks/calendar';
@import 'ItaliaTheme/Blocks/alert';
@import 'ItaliaTheme/Blocks/highlitedContent';
@import 'ItaliaTheme/Blocks/iconBlocks';
@import 'ItaliaTheme/Blocks/imageBlock';
@import 'ItaliaTheme/Blocks/searchSections';
@import 'ItaliaTheme/Blocks/cardWithImage';
@import 'ItaliaTheme/Blocks/cardWithImageTextBlock';
@import 'ItaliaTheme/Blocks/cardWithSlideUpTextTemplate';
@import 'ItaliaTheme/Blocks/smallblockLinkstemplate';
@import 'ItaliaTheme/Blocks/completeBlockLinkstemplate';
@import 'ItaliaTheme/Blocks/squaresImageTemplate';
@import 'ItaliaTheme/Blocks/photogallerytemplate';
@import 'ItaliaTheme/Blocks/sliderTemplate';
@import 'ItaliaTheme/Blocks/inEvidenceTemplate';
@import 'ItaliaTheme/Blocks/contentInEvidenceTemplate';
@import 'ItaliaTheme/Blocks/mapTemplate';
@import 'ItaliaTheme/Blocks/bandiInEvidenceTemplate';
@import 'ItaliaTheme/Blocks/argumentsInEvidence';
@import 'ItaliaTheme/Blocks/gridGalleryTemplate';
@import 'ItaliaTheme/Blocks/ribbonCardTemplate';
@import 'ItaliaTheme/Blocks/break';
@import 'ItaliaTheme/Blocks/accordion';
@import 'ItaliaTheme/Blocks/simpleCardTemplate';
@import 'ItaliaTheme/Blocks/simpleListTemplate';
@import 'ItaliaTheme/Blocks/ctaBlock';
@import 'ItaliaTheme/Blocks/hero';
@import 'ItaliaTheme/Blocks/contacts';
@import 'ItaliaTheme/Blocks/simpleCardTextBlock';
@import 'ItaliaTheme/Blocks/imageCardTextBlock';
@import 'ItaliaTheme/Blocks/eventSearch';
@import 'ItaliaTheme/Blocks/bandiSearch';
@import 'ItaliaTheme/Blocks/form';
@import 'ItaliaTheme/Blocks/videoGallery';
@import 'ItaliaTheme/Blocks/rssBlock';
@import 'ItaliaTheme/Blocks/numbers';
@import 'ItaliaTheme/Blocks/countdown';
@import 'ItaliaTheme/Blocks/video';
@import 'ItaliaTheme/Blocks/HTML';
@import 'ItaliaTheme/Blocks/simpleCardTemplate';
@import 'ItaliaTheme/Blocks/search';
@import 'ItaliaTheme/Blocks/gridBlock';

@import 'ItaliaTheme/Views/uo';
@import 'ItaliaTheme/Views/evento';
@import 'ItaliaTheme/Views/common';
@import 'ItaliaTheme/Views/bando';
@import 'ItaliaTheme/Views/modules';
@import 'ItaliaTheme/Views/cartellaModulistica';
@import 'ItaliaTheme/Views/trasparenzaView';
@import 'ItaliaTheme/Views/paginaArgomento';
@import 'ItaliaTheme/Views/sponsors';
@import 'ItaliaTheme/Views/slider';
@import 'ItaliaTheme/Views/faqFolder';
@import 'ItaliaTheme/Views/folder';
@import 'ItaliaTheme/Views/embeddedVideo';
@import 'ItaliaTheme/Views/homepage';
@import 'ItaliaTheme/Views/puntoDiContatto';
@import 'ItaliaTheme/Views/persona';
@import 'ItaliaTheme/Views/servizio';
@import 'ItaliaTheme/Views/search';
@import 'ItaliaTheme/Views/venue';
@import 'ItaliaTheme/Widgets/iconWidget';
@import 'ItaliaTheme/Widgets/searchSectionsConfigurationWidget';
@import 'ItaliaTheme/Widgets/blocksWidget';
@import 'ItaliaTheme/Widgets/subsiteSocialLinks';
@import 'ItaliaTheme/Widgets/reactSelect';
@import 'ItaliaTheme/Widgets/react-dates-override';
@import 'ItaliaTheme/Widgets/textInput';
@import 'ItaliaTheme/Widgets/dataGridWidget';
@import 'ItaliaTheme/Widgets/canaleDigitaleWidget';
@import 'ItaliaTheme/Widgets/luoghiCorrelatiEventoWidget';
@import 'ItaliaTheme/Components/megamenu';
@import 'ItaliaTheme/Components/sharing';
@import 'ItaliaTheme/Components/logo';
@import 'ItaliaTheme/Components/mobileMenu';
@import 'ItaliaTheme/Components/relatedItemInEvidence';
@import 'ItaliaTheme/Components/pageLoader';
@import 'ItaliaTheme/Components/breadcrumbs';
@import 'ItaliaTheme/Components/card';
@import 'ItaliaTheme/Components/cardPersona';
@import 'ItaliaTheme/Components/officeCard';
@import 'ItaliaTheme/Components/cardLocationItem';
@import 'ItaliaTheme/Components/contactsCard';
@import 'ItaliaTheme/Components/navscroll';
@import 'ItaliaTheme/Components/customerSatisfaction';
@import 'ItaliaTheme/Print/all_pages';
@import 'ItaliaTheme/Print/page';
@import 'ItaliaTheme/Print/news_items';
@import 'ItaliaTheme/Print/bandi';
@import 'ItaliaTheme/Print/persona';
@import 'ItaliaTheme/Print/event';
@import 'ItaliaTheme/Print/servizio';
@import 'ItaliaTheme/Print/uo';
@import 'ItaliaTheme/Print/blocks';

@import 'addonsThemeCustomizationsMain';

/* LIGNANO CUSTOMISATIONS */

/* lignano variables */
@import 'lignano/lignano_variables';

/* lignano common */
@import 'lignano/all_pages';

/* lignano components */

@import 'lignano/components/headerslim';
@import 'lignano/components/headercenter';
@import 'lignano/components/navbarwrapper';
@import 'lignano/components/megamenu';
@import 'lignano/components/mobilemenu';
@import 'lignano/components/footer';
@import 'lignano/components/customersatisfaction';

/* lignano blocks */

@import 'lignano/blocks/icon_blocks';
@import 'lignano/blocks/grid_gallery_template';
@import 'lignano/blocks/card_with_image_template';
@import 'lignano/blocks/complete_block_links_template';
@import 'lignano/blocks/event_search';
@import 'lignano/blocks/argument_in_evidence';

/* No CSS beyond this point */

