.it-header-slim-wrapper {
    background-color: $my-yellow !important;
    //border-top: 1px solid $my-headerblue !important;
    //border-bottom: 1px solid $my-headerblue !important;

    /*border-image: linear-gradient(to left, $my-headerblue, $my-yellow) 30 !important;
    border-width: 1px !important;
    border-bottom-style: solid !important;*/

    .it-header-slim-wrapper-content {

        a {
            color: $my-headerblue !important;
            font-weight: bold;
        }
  }

  .it-header-slim-right-zone {

    /*@media (min-width: 992px) {
      border-bottom: 1px solid $my-headerblue !important;
    }*/

    background-color: $my-yellow !important;

    .btn-primary {
        background-color: $my-yellow;

        &:not(:disabled):not(.disabled):active,
        &:hover {
            background-color: $my-lightyellow;
        }
    }
  }
}



/*@media (min-width: 992px) {

  .it-header-slim-wrapper {

    .it-header-slim-right-zone {
      border-bottom: 1px solid $my-headerblue !important;
    }

  }

}*/
